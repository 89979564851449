.contact-content{
	text-align: center !important;
	p{
		text-align: center !important;
	}
}
[class^="contact-"]{
	form{
		margin-top: 10px;
		span{
			width: 100%;
			display: block;
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			textarea{
				height: 8em;
			}
			&.required{
				position: relative;
				&::after { 
					content:" *"; 
					position: absolute;
				    right: 0px;
				    top: 0.5em;
				    bottom: 0;
				    font-size: 2em;
				    width: 1em;
				    color: black;
				    vertical-align: middle; 
				  	color: $green2;
				}
			}
			select{
			    width: calc(100% - 1em);
			    background-color: rgba(255, 255, 255, 0.9);
			    border: 2px solid rgba(38, 204, 56, 0.3);
			    color: #333333;
			    border-radius: 5px;
			    padding: 0.5em;
			    margin: 0 0.5em;
			    font-size: 1.3em;
			}
			input, textarea{
				width: 100%;
				background-color: rgba(white, 0.9);
				border: 2px solid rgba($green2, 0.3);
				color: $title-color;
				border-radius: 5px;
				padding: 0.5em;
				font-size: 1.3em;
				&[type="submit"], &[type="button"]{
					background-color: $scrollForeground;
				}
				&[type="checkbox"]{
					width: auto;
					position: relative;
					margin: 0.4em;
					&:before{
				  		content: attr(title);
					  	font-size: 1em;
					    text-align: left;
				   		color: $text-color;
				   		left: 1.2em;
					    right: -12em;
					    top: -0.1em;
					    position: absolute;
					    bottom: 0;
				  	}
				}
				&.required{
				}
				&:focus, &::selection{
				    outline: none;
					border: 2px solid $green2;
				}
				&::placeholder{
				  	color: $text-color;//$green2;
				}
				&::-webkit-input-placeholder{ /* Chrome */
				  	color: $text-color;//$green2;
				}
				&:-ms-input-placeholder{ /* IE 10+ */
				  	color: $text-color;//$green2;
				}
				&::-moz-placeholder{ /* Firefox 19+ */
				  	color: $text-color;//$green2;
				}
				&:-moz-placeholder { /* Firefox 4 - 18 */
				  	color: $text-color;//$green2;
				}
				&[type="range"]{
					background-color: transparent;
				    margin: 1em 0 0.5em;
				    position: relative;
			    	width: 100%;
				  	-webkit-appearance: none;
				  	border: none;
					&:before{
				  		content: attr(placeholder) ' (' attr(value) ')';
					  	position: absolute;
					  	font-size: 0.8em;
					    top: -1em;
					    text-align: center;
					    left: 0;
					    right: 0;
				   		color: $text-color;
				  	}
				  	&:focus {
				  		outline: none;
				  		
						@include browser("&::-webkit-slider-runnable-track" 
							"&::-ms-fill-lower" 
							"&::-ms-fill-upper"){
							background: $green4;
						}
					}
					@include browser("&::-moz-range-thumb"
						"&::-webkit-slider-thumb"
						"&::-ms-thumb"){
					  	box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
					  	border: 0px solid #000000;
					  	height: 20px;
					  	width: 39px;
					  	border-radius: 7px;
					  	background: $green1;
					  	cursor: pointer;
					}
					@include browser("&::-webkit-slider-runnable-track"
						"&::-moz-range-track"){
					  	box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
					  	border: 0px solid #000101;
					  	height: 12.8px;
					  	width: 100%;
					  	border-radius: 25px;
						background: $green4;
					  	cursor: pointer;
					  	animate: 0.2s;
					}
					&::-webkit-slider-thumb {
					  	-webkit-appearance: none;
					  	margin-top: -3.6px;
					}
					&::-ms-track {
					  	height: 12.8px;
					  	width: 100%;
					  	cursor: pointer;
					  	animate: 0.2s;
					  	background: transparent;
					  	border-color: transparent;
					  	border-width: 39px 0;
					  	color: transparent;
					}
					@include browser("&::-ms-fill-lower"
						"&::-ms-fill-upper"){
						background: $green4;
					  	border: 0px solid #000101;
					  	border-radius: 50px;
					  	box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
					}
				}
			}
			> span{
				display: inline-block;
				vertical-align: top;
				&:first-child{
					&:nth-last-child(1) {
					    width: calc(100% - 2px);
					}
					&:nth-last-child(2) {
					    width: calc(50% - 2px - 0.5em);
						margin-right: 1em;
					    &~ span{
						    width: calc(50% - 2px - 0.5em);
					    } 
					}
					&:nth-last-child(3) {
					    width: calc(33.3333% - 2px - 0.3333em);
						margin-right: 1em;
					    &~ span{
					    	width: calc(33.3333% - 2px - 0.3333em);
					    } 
					}
					&:nth-last-child(4){
					    width: calc(25% - 2px - 0.25em);
						margin-right: 1em;
					    &~ span{
					    	width: calc(25% - 2px - 0.25em);
					    } 
					}
				}
			}
		}
		.block{
			position: relative;
			.sideText{
				position: absolute;
				top: 50%;
			    left: 1em;
			    span{
					@include transform(translateX(-50%) translateY(-50%) rotate(-90deg));
			    	font-size: 2em;
				}
			}
			.mainText{
			    width: calc(100% - 2em);
			    margin-left: 2em;
			}
			&:not(:last-child) { 
				padding-bottom: 1em;
				border-bottom: $seperatorHeight solid $seperatorColor;
			}
			&:not(:first-child) { 
				padding-top: 1em;
			}
		}
	}
}