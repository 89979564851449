.sell-overview{
	.brackets{
		> div{
			width: 40%;
			float: left;
			margin: 0 5%;
			p{
				text-align: center;
			}
			img{
				width: 100%;
			}
		}
	}
}
.contact-sell-overview{
	[class^="contact-"]{
		form{
			span{
				input, textarea{
					background-color: $green3;
					border: 2px solid rgba($green2, 0.3);
					&:focus, &::selection{
						border: 2px solid $green2;
					}
					&::placeholder{
					  	color: $text-color;//$green2;
					}
					&::-webkit-input-placeholder{ /* Chrome */
					  	color: $text-color;//$green2;
					}
					&:-ms-input-placeholder{ /* IE 10+ */
					  	color: $text-color;//$green2;
					}
					&::-moz-placeholder{ /* Firefox 19+ */
					  	color: $text-color;//$green2;
					}
					&:-moz-placeholder { /* Firefox 4 - 18 */
					  	color: $text-color;//$green2;
					}
				}
			}
		}
	}
	form{
		span{
			&.message{
				textarea{
					height: 6em;
					width: calc(100% - 2px - 9em);
				}
				input{
					float: right;
					height: 6em;
					width: 8em;
				}
			}
			input{
				&.placesInput, &.amountInput{
					margin: 0 0.5em;
    				width: calc(100% - 1em);
				}
			}
			button{
				&.colorSelector{
					border-radius: 0.5em;
				    border: 0;
					width: calc(100% - 1.2em);
				    padding: 0.5em;
				    margin: 0 0.7em;
				}
				&.addToShopCart, &.editToShopCart{
					background-color: $scrollForeground;
					border: 2px solid rgba($green2, 0.3);
					border-radius: 0.5em;
				    border: 0;
					width: calc(100% - 1.2em);
				    padding: 0.5em;
				    margin: 0 0.7em;
				}
			}
		}
	}
}
.cardOverview{
	.cardItem{
		position: relative;
		span{
			display: inline-block;
			width: auto;
			svg{
				height: 7em;
			}
			&.amountText{
			    margin-top: 3.2em;
			    padding: 0;
			    float: left;
			    width: 4em;
			    text-align: right;
			}
			ul{
			    float: left;
			    list-style-type: none;
			}
			&.deleteButton{
			    position: absolute;
			    right: 1em;
			    top: 3.3em;
			    padding: 0;
			}
		}
		&:hover{
			background-color: rgba(black, 0.05);
		}
	}
}
#ral_color_selector_popup{
	position: absolute;
	top: 30px;
	bottom: 30px;
	left: 30px;
	right: 30px;
	.modal-dialog{
    	max-width: 61em;
		width: auto;
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		.modal-content{
			background-color: $text-color;
			h4, button, p{
				color: white;
			}
		}
	}
	.colorSelectorGrid{
		overflow-y: auto;
	    width: calc(100% - 13em - 60px);
	    display: inline-block;
	    position: absolute;
	    left: calc(13em + 30px);
	    right: 15px;
    	text-align: center;
    	height: calc(100% - 30px);
	    .color{
	    	display: inline-block;
	    	border-radius: 0.2em;
	    	margin: 0.3em;
	    	a{
    			padding: 0.8em 1.5em;
	    		display: block;
	    		text-decoration: none;
	    		width: 8em;
			    height: 3.1em;
    			text-align: center;
    			color: white;
    			&.reverse{
    				color: black;
    			}
	    		&:hover{
	    			font-weight: bolder;
	    		}
	    	}
	    }
		&::-webkit-scrollbar {
	      	width:  0.5em;
	      	height: 3em;
	  	}
	  	&::-webkit-scrollbar-thumb {
	      	background: #414141;
    		border-radius: 0.25em;
	  	}
	  	&::-webkit-scrollbar-track {
	      	background: #5D5D5D;
    		border-radius: 0.25em;
	  	}
  		// For Internet Explorer
    	scrollbar-face-color: lightgrey;
    	scrollbar-track-color: darkgray;
	}
	.colorMenu{
		display: inline-block;
    	list-style-type: none;
    	margin: 0;
	    padding: 0;
	    li{
	    	padding: 0.5em 1em;
	    	position: relative;
	    	&.selected{
	    		font-weight: bolder;
	    	}
	    	a{
	    		color: white;
	    		padding-right: 2em;
		    	&:after{
		    		color: white;
		    		position: absolute;
				    right: 10px;
				    font-family: 'Fontello', 'Oxygen', Arial, Helvetica, sans-serif;
				    content: '>';//'\e806';
	    		}
	    	}
	    	&:first-child{
	    		border-radius: 0.5em 0.5em 0 0;
	    	}
	    	&:last-child{
	    		border-radius: 0 0 0.5em 0.5em;
	    	}
	    	&:hover{
	    		a, &:after{
	    			text-decoration: none;
	    		    font-weight: bold;
				}
	    	}
	    }
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	.contact-sell-overview{
		form{
			span{
				&.message{
					textarea{
						width: calc(100% - 2px - 6em);
					}
					input{
						width: 5em;
					}
				}
			}
		}
	}
	#ral_color_selector_popup{
		.colorMenu{
	    	li{
	    		width: 2.5em;
	    		a{
	    			padding-right: 0.1em;
	    			span{
		    			display: none;
		    		}
	    		}
	    	}
		}
		.colorSelectorGrid{
		    left: calc(3em + 30px);
		    width: calc(100% - 2.5em - 60px);
		}
		.modal-dialog{
			.modal-content{
				height: 100%;
				.modal-body{
				    height: calc(100% - 45px);
				}
			}
		}
	}
}