.references{
	.reference{
		position: relative;
		.sizeclass{
			padding-bottom: 66.66%;
		}
		float: left;
		margin-right: 0.5%;
		margin-left: 0.5%;
		margin-bottom: 1%;
		width: 98%;
		overflow: hidden;
		.imageholder{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		    text-align: center;
		    .image{
		    	height: 100%;
		    	/*min-width: 100%;*/
			    text-align: center;
		    }
		}
		.textholder{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding-left: 2%;
			padding-right: 2%;
			background-color: none;
			.title{
				font-size: 1.1em;
				padding-top: 0.2em;
				font-weight: bolder;
			}
			.text{
    			max-height: 0;
    			overflow: hidden;
				padding-bottom: 0.2em;
			}
			background-color: rgba($green4, 0.8);
		}
		&:hover{
			.textholder{
		        @include transition('background-color 0.2s ease-in-out');
				.text{
					max-height: 50em;
		            @include transition('max-height 0.6s ease-in-out');
				}
			}
		}
	}
}
.mobile{
	.references{
		.reference{
			.textholder{
				background-color: rgba($green4, 0.8);
				.text{
    				max-height: 50%;
				}
			}
		}
	}
}
.references_editor{
	&.images{
		.image{
			position: relative;
			display: inline-block;
			img{
				min-height:6em;
				max-height: 300px;
				height: 25vh;
			}
			&.add-image{
				cursor: pointer;
			}
		}
		.buttons{
			position: absolute;
			top: 4px;
			right: 4px;
			color: $green4;
			text-shadow: -1px 0 rgba(black,0.5), 0 1px rgba(black,0.5), 1px 0 rgba(black,0.5), 0 -1px rgba(black,0.5);
			.button{
				cursor: pointer;
			}
		}
	}
}
#reference_popup{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1500;
	max-width: 100%;
	.images{
		height: 22%;
		width: 100%;
		overflow-x: none;
		text-align: center;
	    white-space: nowrap;
	    text-align: center;
	    padding-bottom: $scrollBarHeight;
	    &:hover{
	    	padding-bottom: 0;
	    	overflow-x: auto;
	    }
		.image{
			padding: 5px;
			max-height: 100%;
    		cursor: pointer;
		}
	}
	.mainimage{
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 77%;
	}
	&.touch{
		.images{
			overflow-x: auto;
		}
	}
	.arrows{
	    position: absolute;
    	top: 61%;
	    left: 0;
	    right: 0;
	    .next, .previous{
			color: $green4;
			position: absolute;
			font-size: 1.6em;
			width: 2em;
			margin-top: -0.8em;
			padding: 0.2em;
			vertical-align: middle;
			top: 0;
			cursor: pointer;
	    }
	    .next{
	    	right: 1em;
	    }
	    .previous{
	    	left: 1em;
	    }
	}
}

#imageForm{
	.loading-icon{
		position: absolute;
	    top: 50%;
	    left: 50%;
	    height: 80px;
    	width: 80px;
    	margin-left: -40px;
    	margin-top: -40px;
	    display: none;
	}
	&.loading{
		.loading-icon{
			display: block;
		}
	}
}


@media only screen and (min-width: 2048px) {
	.references{
		.reference{
			width: 24%;
		}
	}
}

@media only screen and (min-width: 1680px) and (max-width: 2047px) {
	.references{
		.reference{
			width: 32%;
		}
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1679px) {
	.references{
		.reference{
			width: 49%;
		}
	}
}