footer{
	background-color: $hf-color;
	height: 8em;
	.footerContent{
		position: relative;
		div{
			position: absolute;
			width: 33%;
			top: 0;
			bottom: 0;
			padding-top: 1.1em;
			span{
				display: block;
			}
			&.blockLeft{
				left: 0;
				span{
					text-align: left;
				}
			}
			&.blockCenter{
				left: 33%;
				span{
					text-align: center;
				}
			}
			&.blockRight{
				right: 0;
				span{
					text-align: right;
				}
			}
		}				
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	footer{
		.footerContent{
			div{
				width: 50%;
				padding-left: 4px;
				padding-right: 4px;
				&.blockCenter{
					display: none;
				}
			}
		}
	}
}