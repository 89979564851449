$numberOfColumns: 5;

body{
	.content{
		.column, [class^="column-"]{
			footer{
				padding: 0;
				background-color: transparent;
			}
		}
	}
}

.column, [class^="column-"]{
	width: calc(100% / #{$numberOfColumns});
	height: 100%;
	top: 0;
	display: inline-block;
	position: absolute;
	z-index: 50;
	overflow: hidden;
	cursor: hand;
	&.hoverout{
		@include transition('all 0.4s ease-in-out');
		text-align: center;
	}
	[class$="-overview"]{
		&:not(:last-child) { 
		    padding-bottom: 1em;
			border-bottom: $seperatorHeight solid $seperatorColor;
		}
	}

	@for $i from 1 through $numberOfColumns{
		&:nth-child(#{$i}){
			left: ($i - 1) * (100% / $numberOfColumns);
			&.hoverout{
				z-index: 65 - (($i - 1) * 5);
			}
			&.hoverin, &.close{
				z-index: 70 + (($i - 1) * 5);
				left: calc(#{($i - 1) * (100% / $numberOfColumns)} - (#{$hoverwidth} / 2));
			}
			&.open{
				z-index: 100;
				top: 0;
			}
		}
	}
	&.column-home{
		background-color: #EEE;
		.titlecolumn{
			background-color: #EEE;
		}
	}
	&.column-rent{
		background-color: $green2;
	}
	&.column-sell{
		background-color: $green3;
	}
	&.column-contact{
		background-color: $green4;
	}
	&.column-electric{
		background-color: $blue;
	}

	&.hoverin{
		width: calc((100% / #{$numberOfColumns}) + #{$hoverwidth});
		@include transition('all 0.4s ease-in-out');
		text-align: center;
	}
	&.close{
		opacity: 1;
	}
	&.open{
		text-align: left;
		//@include transition('all 1s ease-in-out');
		width: 100%;
		left: 0 !important;

		.contentrow{
			cursor: hand;
			&:hover{
				background-color: #5FB4B3;
			}
		}
		.contentcolumn{
			width: calc(((100vw / #{$numberOfColumns}) * (#{$numberOfColumns} - 1)) - 4px);
			//width: calc(((100vw / #{$numberOfColumns}) * 3) - 25px);
			> div{
				max-width: 70em;
				margin: 0 auto;
			}
			//@include transition('all 1.4s ease-in-out');
		}
		.titlecolumn{
			left: 0px;//10px;
			//@include transition('all 0.4s ease-in-out');
			width: calc(100vw / #{$numberOfColumns});
			text-align: left;
			.image{
			}
			.home-icon{
				display: block;
			}
		}
	}

	.contentcolumn{
		width: 20vw;
		left: calc(100vw / #{$numberOfColumns});
		overflow-x: hidden;
		overflow-y: auto;
   		padding-right: 25px;
		z-index: 1;
		padding-top: 3em;
		padding-bottom: 10px;
		.content{
			min-height: calc(100% - 9.4em);
		}
	//	> div{
	//		min-height: calc(100% - 1.4em);
	//	}
		.image_subscription{
			font-size: 0.8em;
			text-align: center !important;
		}
		h1{
			font-size: 1.8em;
		}
		p{
			text-align: justify;
		}
	}
	.titlecolumn{
		color: $title-color;
		width: calc(100vw / #{$numberOfColumns});
		left: 0;
		overflow: hidden;
		text-align: center;
		position: relative;
		.image{
			height: 30vh;
			width: calc((100vw / #{$numberOfColumns}) - 4vw);
			max-width: 30vw;
			max-height: 30vh;
			top: 35vh;
			left: 2vw;
			position: absolute;
		    text-align: center;
			img{
				max-height: 100%;
				max-width: 100%;
				width: auto;
				height: auto;
			}
		}
		.home-icon{
			max-height: 96px;
		    height: 5vh;
		    width: auto;
		    left: 1em;
		    bottom: 1em;
		    top: auto;
		    display: none;
		}
		p{
   			font-family: 'folks-bold';
			bottom: calc(35vh - 1.5em);
			font-size: 4vw;
			text-align: center;
			width: calc((100vw / #{$numberOfColumns}) - 4vw);
			left: 2vw;
			margin: 0;
			padding: 0;
			position: absolute;
			overflow: hidden;
			//opacity: 0;
			//@include transition('all 1s ease-in-out');
		}
	}
	.titlecolumn, .contentcolumn{
		height: 100%;
		display: inline-block;
		/*white-space:nowrap;*/
	}
	&.open .titlecolumn p, &.hoverin .titlecolumn p, &.close .titlecolumn p, .titlecolumn:hover p{
		opacity: 1;
		//@include transition('all 1s ease-in-out');
	}
	&.close .titlecolumn p{
		-webkit-transition-delay: 1s; /* Safari */
   		transition-delay: 1s;
	}
	.contentrow{
		clear: left;
		overflow: hidden;
		> .preview{
   			width: $hoverwidth * 0.9;
   			> img{
   				max-height: 100%;
   				max-width: 100%;
   			}
		}
		> .content{
			white-space: normal;
			width: calc((200vw / #{$numberOfColumns}) - (#{$hoverwidth} * 1.1));
			> :first-child {
				margin-top: 0;
			}
			> :last-child {
				margin-bottom: 0;
			}
		}
		> .preview, > .content{
			padding: $hoverwidth * 0.05;
			float: left;
		}
		&.open{
			//@include transition('all 1s ease-in-out');
		}
		&.close{
			//@include transition('all 0.8s ease-in-out');
		}
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
    /* For mobile phones: */
	.column, [class^="column-"]{
		width: 100%;
		height: calc(100% / #{$numberOfColumns});
		@for $i from 1 through $numberOfColumns{
			&:nth-child(#{$i}){
				left: 0;
				top: ($i - 1) * (100% / $numberOfColumns);//0;
				&.hoverin, &.close{
					top: ($i - 1) * (100% / $numberOfColumns);//0;
					left: 0;
				}
				&.open{
					z-index: 100;
					top: 0;
				}
			}
		}
		
		&.hoverin{
			width: 100%;
			height: calc(100% / #{$numberOfColumns});
			@include transition('all 0.4s ease-in-out');
			text-align: center;
			ul{
				text-align: left;
			}
		}
		&.close{
			opacity: 1;
		}
		&.open{
			@include transition('all 1s ease-in-out, z-index 0.1s');
			height: 100%;
			left: 0 !important;
			top: 0;

			.contentcolumn{
//				height: calc(((100vh / #{$numberOfColumns}) * (#{$numberOfColumns} - 1)) - 25px);
				height: calc(((100vh / #{$numberOfColumns}) * (#{$numberOfColumns} - 1)) - 4px);
				width: 100%;
				@include transition('height 1.4s ease-in-out');
			}
			.titlecolumn{
				left: 0;
				top: 0;
				width: 100%;
				@include transition('height 0.4s ease-in-out');
				height: calc(100vh / #{$numberOfColumns});
			}
		}

		.contentcolumn{
			padding-top: 10px;
			height: 20vh;
			top: calc(100vh / #{$numberOfColumns});
			padding-right: 10px;
   			padding-left: 10px;
		}
		.titlecolumn{
			height: calc(100vh / #{$numberOfColumns});
			top: 0;
			.image{
				height: (100vh / $numberOfColumns) - 10vh;
				width: 30vw;
				max-width: 30vw;
				max-height: 30vh;
				top: 5vh;
				left: 35vw;
				img{
					max-height: 100%;
					max-width: 100%;
					width: auto;
					height: auto;
				}
			}
			.home-icon{
				left: 1em;
			    bottom: auto;
			    top: 1em;
			}
			p{
				bottom: calc(5vh - 1.5em);
				width: calc(96vw);
				opacity: 1;
				font-size: 3vh;
				@include transition('all 1s ease-in-out');
			}
		}
		.titlecolumn, .contentcolumn{
			width: 100%;
		}			
		.contentrow{
			> .content{
				width: calc(100vw - (#{$hoverwidth} * 1.1));
			}
		}
	}
}