.alert{
	z-index: 10000;
    position: absolute;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
}
#image_popup{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1500;
	cursor: zoom-out;
	.mainimage{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100%;
	}
}