header{
	background-color: $hf-color;
	position: fixed;
	top: 0;
	margin: 0 auto;
	height: 9em;
	overflow: hidden;
	.icon{
		height: 9em;
		img{
			margin: 0.5em;
			height: 8em;
			padding: 0 1em;
		}
	}
	nav{
		height: 9em;
		margin-top: 0em;
		position: absolute;
		display: inline-block;
		a{
			height: 9em;
		    vertical-align: middle;
		    display: table-cell;
		    padding: 0.5em 2em;
    		text-decoration: none;
			&:hover{
				background-color: $green6;
    			text-decoration: none;
			}
			&.active, &.active:hover{
				background-color: $green5;
			}
		}
	}
	.right-menu{
		float: right;
	    position: relative;
		right: 0vw;
	}
	&.smaller{
		height: 3em;
		.icon{
			height: 3em;
			img{
				margin-top: -2em;
			}
		}
		nav{
			height: 3em;
			margin-top: 0em;
			a{
				height: 3em;
		    	padding: 0.5em 2em;
			}
		}
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	header{
		.icon{
			display: none;
		}
		nav{
			a{
				padding: 0 1em;
			}
		}
		&.smaller{
			.icon{
				img{
				}
			}
			nav{
				a{
		    		padding: 0.5em 1em;
				}
			}
		}
	}
}
