$numberOfColumns: 5;

$splitBackgroundColor: #45c7a5;

body{
	.content{
		.splitColumn, [class^="splitColumn-"]{
			footer{
				padding: 0;
				background-color: transparent;
			}
		}
	}
}

.splitColumns{
	.column, [class^="column-"]{
		width: ((100% - 6%) / $numberOfColumns) - 6%;
		height: 76vh;
		margin: 12vh 3%;
		background-color: transparent;
		opacity: 1;
		&.open{
		    width: (100% - 12%);
			@include transition('all 1s ease-in-out');
		    left: 3% !important;
		    .contentColumn{
		    }
		}
		@include transition('all 1s ease-in-out');
		&.hide{
			opacity: 0;
			@include transition('all 1s ease-in-out');
		}
		.columncontent{
			width: (100vw - 6vw);
		    height: 100%;
		    overflow-y: hidden;
			.content{
				min-height: calc(100% - 9.5em);
			}
		}
		.titlecolumn{
			width: ((100vw - 6vw) / $numberOfColumns) - 6vw;
			background-color: $splitBackgroundColor;
			p{
				@include user-select(none);
				text-shadow: 0px 0px 6px rgba($text-color, 0.7);
				font-family: 'BankGothic LT BT';
				font-size: 3vw;
				font-weight: bolder;
				writing-mode: vertical-rl;
				text-orientation: upright;
				bottom: 0;
				top: 0;
				left: calc(50% - 0.5em);
				width: 1.2em;
				text-transform: uppercase;
			}
		}
		@for $i from 1 through $numberOfColumns{
			&:nth-child(#{$i}){
				left: ($i - 1) * ((100% - 6) / $numberOfColumns) + 3%;
				text-align: inherit;
			}
		}
		.contentcolumn{
			background-color: $splitBackgroundColor;
			margin-left: 6vw;
			padding-left: 25px;
			width: calc(#{((100vw / $numberOfColumns) * ($numberOfColumns - 1)) - 11vw} - #{$scrollBarHeight});
		}
	}
	.column-references{
		.contentcolumn{
			padding-left: 0;
			padding-right: 0;
			background-color: transparent;
			@import "floatingReferenceLayout.scss";
			.references{
				.reference{
					background-color: $splitBackgroundColor;
				}
			}
		}
	}
}


@media only screen and (max-width: 768px) and (orientation:portrait) {
	.splitColumns{
		.column, [class^="column-"]{
			height: ((100vh - 6vh) / $numberOfColumns) - 6vh;
			width: 100vw - 12vw;
			margin: 3vh 3vw;
			&.open{
			    width: (100% - 12%);
			    height: 100vh - 12vh;
			    top: 3vh !important;
			}
			.columncontent{
				width: (100vw - 12vw);
			    height: 100%;
				.content{
					min-height: calc(100% - 9.5em);
				}
			}
			.titlecolumn{
				width: 100%;
				height: ((100vh - 6vh) / $numberOfColumns) - 6vh;
				p{
					writing-mode: horizontal-tb;
					text-orientation: sideways;
					bottom: 0;
					top: (((100vh - 6vh) / $numberOfColumns) - 6vh - 3vh) / 2;
					left: 0;
					right: 0;
					width: 100%;
				}
			}
			@for $i from 1 through $numberOfColumns{
				&:nth-child(#{$i}){
					left: 3vw;
					top: (((100vh - 6vh) / $numberOfColumns) * ($i - 1)) + 3vh;
				}
			}
			.contentcolumn{
				margin-left: 0;
				padding-left: 10px;
				margin-top: 6vh;
				height: 100vh - ((100vh - 6vh) / $numberOfColumns) - 6vh;
				width: 100%;
			}
		}
		.column-contact .columncontent .contentcolumn .content{
			min-height: calc(100% - 12em);
		}
	}
}