$hoverwidth: 0vw;//14vw

$text-color: #333333;
$title-color: #333333;

$green1: #26DD38;
$green2: #26CC38;
$green3: #26BB38;
$green4: #26AA38;
$green5: #26EE38;
$green6: #26FF38;
$blue: #4286f4;

$hf-color: rgba(white, 0.7);

$scrollBackground: $green4;
$scrollForeground: $green5;

$maingreen: $green1;

$scrollBarHeight: .5em;

$seperatorColor: rgba(0, 0, 0, 0.1);
$seperatorHeight: 3px;