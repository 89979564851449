$maxWidth: 1200px;

body, html{
	text-align: justify;
	fill: currentColor;
	color: $text-color;
	height: calc(100% - 16em);
	a, a:hover, a:visited{
		color: $text-color;
	}
	svg{
		fill: currentColor;
		max-height: 100%;
		max-width: 100%;
	}
	h1, h2, h3, h4, h5, h6{
		text-align: left;
		&.center{
			text-align: center;
		}
	}
}

@include scrollbars($scrollBarHeight, $scrollForeground, $scrollBackground);

body{
	.content{
		min-height: 100%;
		header, footer{
			padding: 0 15vw;
			width: 100%;
			z-index: 1000;
		}

		#content{
			z-index: 100;
			background-color: $hf-color;
			margin: 10em 15vw 1em;
			border-radius: 0.4em;
			padding: 2em;
			min-height: calc(100vh - 19em);
		}

		.background{
			background-color: rgba($maingreen, 0.2);
			background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center;
			position: fixed;
			height: 100vh;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
	}
}

$info-top-left-shadow-opacity: 0.2;
$info-top-left-shadow-color: black;
.flipbook-parent{
	position: relative;
	height: 100%;
	.flipbook{
		cursor: zoom-in;
	}
	.info-top-left{
		position: absolute;
		top: 4px;
		left: 4px;
		z-index: 9999;
		color: $green4;
		@include user-select(none);
		cursor: default;
		text-shadow: -1px 0 rgba($info-top-left-shadow-color, $info-top-left-shadow-opacity), 0 1px rgba($info-top-left-shadow-color, $info-top-left-shadow-opacity), 1px 0 rgba($info-top-left-shadow-color, $info-top-left-shadow-opacity), 0 -1px rgba($info-top-left-shadow-color, $info-top-left-shadow-opacity);
	}
	.flipbookSelectors{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		.circle {
			display: inline-block;
		  	background-color: transparent;
		  	height: 8px;
		  	width: 8px;
		  	border-radius: 100%;
		  	border: 2px solid $green4;
		  	margin: 1px 3px;
		  	cursor: pointer;
		  	&.active{
		  		background-color: $green4;
		  	}
		}
	}
	.flipbookArrows{
		display: none;
		.arrow{
			color: $green4;
			position: absolute;
			font-size: 1.6em;
			width: 2em;
			padding: 0.2em;
			vertical-align: middle;
			top: calc(50% - 0.8em);
			bottom: calc(50% - 0.8em);
			cursor: pointer;
			&.previous{
				left: 0;
			}
			&.next{
				right: 0;
			}
		}
	}
	&:hover{
		.flipbookArrows{
			display: block;
		}
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	body{
		.content{
			header, footer{
				padding: 0;
			}

			#content{
				margin: 9em 0em 0em;
				border-radius: 0em;
				min-height: calc(100vh - 17em);
			}

			#background{
			}
		}
	}
}
