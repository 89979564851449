.rent-overview{
	.design-image{
		text-align: center;
	}
}
.contact-rent-overview{
	[class^="contact-"]{
		form{
			span{
				input, textarea{
					background-color: $green2;
					border: 2px solid rgba($green1, 0.3);
					&:focus, &::selection{
						border: 2px solid $green1;
					}
					&::placeholder{
					  	color: $text-color;//$green1;
					}
					&::-webkit-input-placeholder{ /* Chrome */
					  	color: $text-color;//$green1;
					}
					&:-ms-input-placeholder{ /* IE 10+ */
					  	color: $text-color;//$green1;
					}
					&::-moz-placeholder{ /* Firefox 19+ */
					  	color: $text-color;//$green1;
					}
					&:-moz-placeholder { /* Firefox 4 - 18 */
					  	color: $text-color;//$green1;
					}
				}
			}
		}
	}
	form{
		span{
			&.remarks{
				textarea{
					height: 10em;
					width: calc(100% - 2px - 11em);
				}
				input{
					float: right;
					height: 10em;
					width: 10em;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	.contact-rent-overview{
		form{
			span{
				&.remarks{
					textarea{
						width: calc(100% - 2px - 6em);
					}
					input{
						width: 5em;
					}
				}
			}
		}
	}
}