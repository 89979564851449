$leftWidth: 45em;

.home-overview{
	> div{
		display: inline-block;
		&.column1{
			width: $leftWidth;	
			height: calc(#{$leftWidth} / 1.5);
			vertical-align: top;
			text-align: center;
    		overflow: hidden;
    		position: relative;
			img{
				height: auto;
				width: auto;
			    max-width: 100%;
			    max-height: 100%;
			}
		}
		&.column2{
			margin-left: 1em;
			width: calc(99% - #{$leftWidth} - 1em);
		}
	}
}

@media only screen and (max-width: 768px) and (orientation:portrait) {
	.home-overview{
		> div{
			&.column1{
				width: 100%;
				height: 75vw;
			}
			&.column2{
				margin-left: 0;
				width: 100%;
			}
		}
	}
}

$leftWidth: 25em;
@media only screen and (min-width: 769px) and (max-width: 1420px) {
	.home-overview{
		> div{
			&.column1{
				width: $leftWidth;
				height: calc(#{$leftWidth} / 1.5);
			}
			&.column2{
				width: calc(99% - #{$leftWidth} - 1em);
			}
		}
	}
}